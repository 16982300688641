/* Breakpoints */
$small: 720px;
$desktop: 720px;

@font-face {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Apple Color Emoji", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*font-family: 'OpenSans';*/
  /*src: url("./assets/scss/fonts/OpenSans-Regular.woff") format("woff");*/
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'OpenSans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  scroll-behavior: smooth;
}

.container {
  margin: 0 auto;
  padding: 0 18rem;
  overflow-x: hidden;
  overflow-y: hidden;

  @media (max-width: 768px) {
    padding: 0 11rem;
  }
}

.swiper-wrapper{
  @media (max-width: 768px) {
    margin-left: -62%;
  }
}