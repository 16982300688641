.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
    border-bottom: 1px solid white;

    @media (max-width: 768px) {
        padding: 9rem 0 10rem;
    }
}

.logo {
    font-size: 3rem;
    font-weight: 700;
    line-height: 4rem;
    color: white;
    height: 3.5rem;

    @media (max-width: 768px) {
        font-size: 9rem;
        line-height: 13rem;
        height: 11rem;
    }
}

.menu {
    display: flex;
    align-items: center;
    color: white;
}

.item {
    cursor: pointer;
    margin-right: 7rem;
    @media (max-width: 768px) {
        display: none;
    }
    z-index: 1;
}

.item:last-of-type {
    margin-right: 0;
}

.link {
    font-weight: 600;
    display: block;
    font-size: 2.5rem;
    @media (max-width: 768px) {
        display: block;
        font-size: 7rem;
    }
}

.contacts {
    padding: 1rem 5rem;
    background-color: #32FFB9;
    border-radius: 10px;
    color: #1C1C1E;
    @media (max-width: 768px) {
        display: block;
        padding: 2rem 7rem;
    }
}
