.WorkWithUs {
    margin-bottom: 30rem;
    padding-bottom: 10rem;
}

.heading {
    text-align: center;
    font-size: 10rem;
    line-height: 1;
    font-weight: 700;
    color: white;
    margin-bottom: 9rem;

    @media (max-width: 768px) {
        font-size: 16.4rem;
    }
}

.heading span {
    color: #B168E7;
}
