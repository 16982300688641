.Cases {
    margin-bottom: 30rem;
}

.heading {
    font-size: 10rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 6rem;
    color: white;
    @media (max-width: 768px) {
        font-size: 16.4rem;
        margin-bottom: 12rem;
    }
}

.slide {
    position: relative;
    background-repeat: no-repeat;
    border: 2px solid #B168E7;
    border-radius: 2.5rem;
    @media (max-width: 768px) {
        border-radius: 5rem;
    }
}
.slideImage{
    height: 100%;
}

.icon {
    width: 1rem;
    height: 2rem;
    color: #32FFB9;
    color: #B168E7;
    @media (max-width: 768px) {
        width: 4rem;
        height: 7.7rem;
        color: #B168E7;
    }
}

.roundedBtn {
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #32FFB9;
    border: 2px solid #B168E7;
    border-radius: 50%;
    cursor: pointer;
    transition: all .3s ease;

    @media (max-width: 768px) {
        width: 18.2rem;
        height: 18.2rem;
        border-color: #B168E7;
    }
}

.roundedBtn:first-of-type {
    margin-right: 3rem;
}

.roundedBtn:hover {
    transform: scale(1.03);
}

.navigation {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
}

.swiperWrap {
    height: 70rem;

    @media (max-width: 768px) {
        height: 240rem;
        margin-bottom: 15rem;
    }
}


.iphone {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: calc(65rem * .461);
    border-radius: 1rem;
    background-repeat: no-repeat;
}
