.Accordion {

}

.header {
    display: flex;
    align-items: center;
    padding: 4.5rem 0;
    cursor: pointer;
    z-index: 1;
    position: relative;
    
    @media (max-width: 768px) {
        padding: 9rem 0;
    }

    img {
        width: 5.5rem;
        margin-right: 3rem;

        @media (max-width: 768px) {
            width: 18.2rem;
            height: auto;
            margin-right: 9rem;
        }
    }
}

.title {
    font-weight: 600;
    font-size: 4rem;
    line-height: 1;
    color: #F5F5F7;
    margin-right: auto;
    transition: all .3s ease;
    background-image: linear-gradient(to right, #0CFFAB, #F42BFF);
    -webkit-background-clip: text;
    background-clip: text;
    
    @media (max-width: 768px) {
        font-size: 9.1rem;
    }
}

.title:hover {
    transform: scale(1.005);
}

.activeTitle{
    color: transparent;
}

.contentWrap{
    font-size: 2.8rem;
    line-height: 3.6rem;
    color: #F5F5F7;
    transition: all .3s ease;
    padding: 0 3rem;
    
    @media (max-width: 768px) {
        font-size: 7.3rem;
        line-height: 7.7rem;
        padding: 0 7rem;
    }
}

.icon {
    width: 4.3rem;
    height: 4.3rem;
    position: relative;

    @media (max-width: 768px) {
        width: 9rem;
        height: 9rem;
        flex-shrink: 0;
    }
}

.horizontal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: .8rem;
    background-color: #B168E7;
    border-radius: .8rem;
}

.vertical {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: .8rem;
    height: 100%;
    background-color: #B168E7;
    border-radius: .8rem;
    transition: all .3s ease;
}

.verticalOff {
    transform: translate(-50%, -50%) rotate(90deg);
}

.borderBottom {
    border-bottom: 1px solid #B168E7;
}
