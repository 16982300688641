.TheFooter {
    padding: 7.7rem 0 9.6rem;
    color: white;
    display: flex;
    border-top: 1px solid white;
    
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 22.7rem;
        padding-bottom: 27.3rem;
    }
}

.logo {
    font-weight: 700;
    font-size: 5rem;
    line-height: 6.5rem;
    color: #F5F5F7;
    width: 36.4rem;
    margin-right: 25.3rem;

    @media (max-width: 768px) {
        margin-right: 0;
        width: unset;
        font-size: 13rem;
        margin-bottom: 18.2rem;
        line-height: 1;
    }
}

.contacts {
    font-size: 3.5rem;
    line-height: 4.5rem;
    color: #F5F5F7;

    @media (max-width: 768px) {
        font-size: 9.1rem;
        line-height: 11.8rem;
        text-align: center;
        order: 3;
    }

    a {
        display: block;

        &:first-of-type {
            margin: 1.5rem 0 1rem;
        }
    }
}

.social {
    margin-left: auto;

    @media (max-width: 768px) {
        margin-left: unset;
        margin-bottom: 18.2rem;
        order: 1;
    }

    svg {
        width: 9.6rem;
        height: 9.6rem;

        &:not(:last-of-type) {
            margin-right: 3.5rem;
            
            @media (max-width: 768px) {
                margin-right: 24.5rem;
            }
        }
        
        @media (max-width: 768px) {
            width: 28.6rem;
            height: 28.6rem;
        }
    }
}
