.MainWorkDirection {
    margin-bottom: 30rem;

    @media (max-width: 768px) {
        margin-bottom: 45rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.heading {
    font-size: 10rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 8rem;
    color: white;

    @media (max-width: 768px) {
        font-size: 16.4rem;
        margin-bottom: 12rem;
    }

    span {
        color: #B168E7;
    }
}

.dFlex {
    display: flex;
}

.direction {
    height: 69.5rem;

    @media (max-width: 768px) {
        display:none;
    }
}

.corporate {
    display: flex;
    flex-direction: column;
    width: 50.5rem;
    height: 100%;
    background-color: #B168E7;
    padding: 6rem 5rem;
    border-radius: 25px;
    margin-right: 2rem;
}

.title {
    font-size: 4rem;
    color: white;
    margin-bottom: 6rem;
    font-weight: 600;
}

.corporateImg {
    width: 90%;
    margin: 0 auto;
    transition: all .2s linear;
}

.middle {
    width: 50.5rem;
    margin-right: 2rem;
    position: relative;
}

.right {
    width: 50.5rem;
    position: relative;
}

.topMiddleCard {
    padding: 4rem;
    width: 100%;
    height: 27.8rem;
    margin-bottom: 2rem;
    display: flex;
    background-color: #B168E7;
    border-radius: 25px;
}

.topRightCard {
    position: relative;
    padding: 3rem 4rem;
    width: calc(100% - .4rem);
    height: calc(27.8rem - .4rem);
    display: flex;
    border-radius: 2.5rem;
}

.gradientBackground {
    position: relative;
    height: 27.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2.5rem;
    margin-bottom: 2rem;
    backdrop-filter: blur(20px);
    background: rgba(0,0,0,0.4);
    border: 2px solid rgba(255,255,255,0.06);
}


.middle .gradientBackground {
    height: 18.8rem;
}

.bottomCard {
    width: calc(100% - .4rem);
    height: calc(18.8rem - .4rem);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2.5rem;
    padding: 0 5rem;
}

.bigBottomCard {
    width: 100%;
    height: 39.1rem;
    background-image: linear-gradient(to right, #0CFFAB, #BB5FEA);
    border-radius: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.blackInnerBorder {
    background-color: #1C1C1E;
    width: calc(100% - .4rem);
    height: calc(100% - .4rem);
    border-radius: 2.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
}

.bigBottomCardContent {
    display: flex;
    flex-direction: column;
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    padding: 4rem 4.5rem 2rem;
    background-color: #fff;
    border-radius: 1.8rem;
}

.bigBottomCardContent h6 {
    font-size: 4rem;
    font-weight: 600;
    color: #1C1C1E;
    margin-bottom: 1.7rem;
}

.landingImg {
    width: 21.6rem;
    height: 23.4rem;
    transform: translateY(-1rem);
    transition: all .2s linear;
}

.gradientTitle {
    font-size: 4rem;
    font-weight: 600;
    background-image: linear-gradient(to right, #0CFFAB, #BB5FEA);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.web3Img {
    width: 20rem;
    height: 21rem;
    margin-left: 4.5rem;
}

.miniGamesImg {
    width: 16.7rem;
    height: 14.8rem;
}

.shopImg {
    width: 17.8rem;
    height: 16.1rem;
}

.applicationImg {
    width: 24.7rem;
    height: 24.7rem;
    margin-left: auto;
}

.bottomCard:hover {
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
    transition: box-shadow .5s ease-in-out;
}

.bigBottomCard:hover {
    box-shadow: 0 5px 35px 0 rgba(0,0,0,.1);
}

.bigBottomCard:hover::before,
.bigBottomCard:hover::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #FDA8CF;
    border-radius: 2.5rem;
    z-index: -1;
}

@keyframes clockwise {
    0% {
        top: -5px;
        left: 0;
    }
    12% {
        top: -2px;
        left: 2px;
    }
    25% {
        top: 0;
        left: 5px;
    }
    37% {
        top: 2px;
        left: 2px;
    }
    50% {
        top: 5px;
        left: 0;
    }
    62% {
        top: 2px;
        left: -2px;
    }
    75% {
        top: 0;
        left: -5px;
    }
    87% {
        top: -2px;
        left: -2px;
    }
    100% {
        top: -5px;
        left: 0;
    }
}

@keyframes counterclockwise {
    0% {
        top: -5px;
        right: 0;
    }
    12% {
        top: -2px;
        right: 2px;
    }
    25% {
        top: 0;
        right: 5px;
    }
    37% {
        top: 2px;
        right: 2px;
    }
    50% {
        top: 5px;
        right: 0;
    }
    62% {
        top: 2px;
        right: -2px;
    }
    75% {
        top: 0;
        right: -5px;
    }
    87% {
        top: -2px;
        right: -2px;
    }
    100% {
        top: -5px;
        right: 0;
    }
}

.fonBlock {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -2;
}
.fonBlock2 {
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    z-index: -2;
}

.fon1 {
    border-radius: 1323.577px;
    opacity: 0.3;
    background: radial-gradient(50% 50% at 50% 50%, #31D79E 0%, rgba(49, 215, 158, 0.00) 100%);
    filter: blur(100px);
    width: 70rem;
    height: 71rem;
    position: absolute;
    top: -30rem;
    right: 5rem
}

.fon2 {
    border-radius: 1647px;
    opacity: 0.3;
    background: radial-gradient(50% 50% at 50% 50%, #F42BFF 0%, rgba(244, 43, 255, 0.00) 100%);
    filter: blur(50px);
    width: 50rem;
    height: 70rem;
    position: absolute;
    top: -20rem;
    right: -13rem;
}

.mobileCards {
    width: 163rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7rem;

    @media (min-width: 769px) {
        display: none;
    }

    .cardHeading {
        color: white;
        font-size: 8rem;
        font-weight: 700;
    }

    .description {
        font-size: 6.4rem;
        line-height: 1;
        color: white;
        width: 67rem;
    }

    .first {
        position: relative;
        display: flex;
        gap: 7rem;
        justify-content: center;

        .miniGames {
            display: flex;
            flex-direction: column;
            width: 78rem;
            height: 78rem;
            border: 2px solid #283A39;
            border-radius: 20px;
            padding: 11.4rem;

            & .cardHeading {
                color: white;
                font-size: 8rem;
            }

            img {
                width: 43rem;
                height: 37rem;
                margin-left: auto;
                margin-top: auto;
            }
        }

        .internetMarket {
            display: flex;
            flex-direction: column;
            width: 78rem;
            height: 78rem;
            border-radius: 20px;
            padding: 11.4rem;
            background-color: #B168E7;

            .cardHeading {

            }

            img {
                width: 43rem;
                height: 37rem;
                margin-top: auto;
                margin-left: auto;
            }
        }
    }

    .corporateSite {
        position: relative;
        width: 100%;
        background-color: #B168E7;
        border-radius: 7rem;
        padding: 16rem 12rem;

        .cardHeading {
        }

        .description {

        }

        img {
            position: absolute;
            right: 10rem;
            top: 5.5rem;
            width: 61.4rem;
            height: 64.5rem;
        }
    }

    .second {
        display: flex;
        gap: 7rem;
        justify-content: center;

        .web3 {
            display: flex;
            flex-direction: column;
            width: 78rem;
            height: 78rem;
            border: 2px solid #283A39;
            border-radius: 20px;
            padding: 11.4rem;

            .cardHeading {

            }

            img {
                width: 37rem;
                height: 42rem;
                margin-top: 3rem;
                margin-left: auto;
            }
        }

        .application {
            display: flex;
            flex-direction: column;
            width: 78rem;
            height: 78rem;
            border: 2px solid #283A39;
            border-radius: 20px;
            padding: 11.4rem;

            .cardHeading {

            }

            img {
                width: 66rem;
                height: 66rem;
                margin-top: auto;
                margin-left: auto;
                transform: translate(2rem, -5rem);
            }
        }
    }

    .landing {
        position: relative;
        display: flex;
        width: 100%;
        background-color: #B168E7;
        border-radius: 9.1rem;
        padding: 4rem 11rem 5rem;

        img {
            width: 63rem;
            height: 68.1rem;
        }

        .text {
            margin-left: auto;
            margin-top: 17rem;
        }

        .cardHeading {
            margin-bottom: 5rem;
        }

        .description {
        }
    }
}
